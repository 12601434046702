::selection {
  background: #faed27; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #faed27; /* Gecko Browsers */
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: Powderblue;
  font-size: 16px;
}

a { color: #191919; }
a:visited { color: #191919; }
